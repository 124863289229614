'use client';

import { Modal } from 'antd';
import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 32px !important;
    border-radius: 22px !important;
    background: #fff4e4 !important;
    border: 2px solid #fcdcab !important;
  }
`;

const ValentinePopup = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  React.useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited');

    if (!hasVisited) {
      const timer = setTimeout(() => {
        setModalOpen(true);
        localStorage.setItem('hasVisited', 'true');
      }, 7000);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <CustomModal
      closeIcon={true}
      footer={[]}
      open={modalOpen}
      centered
      onCancel={() => setModalOpen(false)}
      onClose={() => setModalOpen(false)}
    >
      <div className='relative -mb-4 mt-4 h-[450px] w-full self-center md:h-[568px]'>
        <Image alt='valentine popup' src='/images/Event/valentine.png' fill className='object-contain' />
      </div>
    </CustomModal>
  );
};

export default ValentinePopup;
